import { Component, inject, input } from '@angular/core';
import { ICurrentUser } from '../../models/current-user.model';
import { NgOptimizedImage } from '@angular/common';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';

@Component({
  selector: 'fusable-user-info-preview',
  imports: [NgOptimizedImage, MatMenu, MatMenuTrigger, MatMenuItem],
  templateUrl: './user-info-preview.component.html',
  styleUrl: './user-info-preview.component.scss',
})
export class UserInfoPreviewComponent {
  info = input.required<ICurrentUser | null>();
  router = inject(Router);

  handleLogoutClick(): void {
    localStorage.removeItem('token');
    this.router.navigate(['/auth/login']);
  }
}
