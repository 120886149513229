import { HttpInterceptorFn } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { getUiApiAuthToken } from '../utilities/token.utils';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
    const token = getUiApiAuthToken();
    if (req.url.includes(environment.FusableUiApi) && token) {
        const requestWithAuthorization = req.clone({
            headers: req.headers.set(
                'Authorization',
                'Bearer ' + token
            ),
        });
        return next(requestWithAuthorization);
    }
    return next(req);
};