<div class="d-flex pe-2">
  <div class="pe-2">
    <p class="mb-0 mt-2">{{ info()?.name }}</p>
    <h6 class="mb-0">{{ info()?.role }}</h6>
  </div>
  <div class="cursor-pointer">
    @if(info()?.name){
        <div class="user-icon" [matMenuTriggerFor]="menu">
          {{ info()?.name?.charAt(0) }}
        </div>
        } @else {
        <img
          ngSrc="images/icons/user.svg"
          alt="User"
          height="50"
          [matMenuTriggerFor]="menu"
          width="50"
          priority="low"
        />
        }
  </div>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="handleLogoutClick()">Logout</button>
  </mat-menu>
</div>
