import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { of, switchMap } from 'rxjs';

export const authGuard: CanActivateFn = (route, state) => {
  let authService = inject(AuthService);
  let router = inject(Router);

  return authService.isLoggedIn().pipe(
    switchMap((isAuthenticated: boolean) => {
      if (isAuthenticated) {
        console.log('Authorized');
        return of(true);
      } else {
        console.log('Not authorized');
        authService.redirectUrl = state.url;
        router.navigate(['/auth']);
        return of(false);
      }
    })
  );
};
