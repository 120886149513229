import { Component, inject, OnInit } from '@angular/core';
import { ICurrentUser } from '../../models/current-user.model';
import { CurrentUserService } from '../../services/current-user.service';
import { UserInfoPreviewComponent } from '../user-info-preview/user-info-preview.component';

@Component({
  selector: 'fusable-header',
  imports: [
    UserInfoPreviewComponent
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {

  userInfo: ICurrentUser | null = null;
  private readonly currentUserService = inject(CurrentUserService);

  ngOnInit(): void {
    this.userInfo = this.currentUserService.getUserInfo();
  }
}
