import { Component, inject } from '@angular/core';
import { LayoutComponent } from './pages/layout/layout.component';
import { AuthService } from './services/auth.service';
import { RouterOutlet } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { LoaderService } from './services/loader.service';

@Component({
    selector: 'app-root',
    imports: [
        LayoutComponent,
        RouterOutlet,
        AsyncPipe,
        LoaderComponent
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent {
    readonly authService = inject(AuthService);
    readonly loaderService = inject(LoaderService);
}
