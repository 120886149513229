import { NgOptimizedImage } from '@angular/common';
import {
  Component,
  inject,
  OnInit
} from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive
} from '@angular/router';
import { filter } from 'rxjs';
import { INavItem } from '../../models/nav-item.model';
import { NavService } from '../../services/nav.service';
import { HeaderLogoComponent } from '../header-logo/header-logo.component';

@Component({
  selector: 'fusable-sidenav',
  imports: [
    HeaderLogoComponent,
    NgOptimizedImage,
    RouterLink,
    RouterLinkActive,
  ],
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss',
})
export class SidenavComponent implements OnInit {
  navItems: INavItem[] = [];

  private readonly navService = inject(NavService);
  private readonly router = inject(Router);

  ngOnInit(): void {
    this.navItems = this.navService.getNavItems();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updateNavItemsState();
      });
  }

  updateNavItemsState(): void {
    this.navItems.forEach((item) => {
      if (item.children && item.children.length > 0) {
        item.isExpanded = this.isChildActive(item); // Update isExpanded state for each nav item
      }
    });
  }

  toggleExpand(item: INavItem): void {
    if (item.children) {
      item.isExpanded = !item.isExpanded;
    }
  }

  isChildActive(item: any): boolean {
    const currentUrl = this.router.url;
    return item.children?.some((child: any) =>
      currentUrl.includes(child.routerLink)
    );
  }
}
